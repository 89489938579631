/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from "react"

import { navigate } from "gatsby"

import { proposalHttpController } from "@garantidos/services"
import { setItem } from "@garantidos/utils"

const useProcessData = () => {
  const getQueryParams = (search) => {
    if (!search) return null

    const queries = new URLSearchParams(search).entries()

    const objQueries = [...queries].reduce((obj, item) => {
      const [key, value] = item
      return {
        ...obj,
        [key]: value
      }
    }, {})

    return objQueries
  }

  const renewTime = (valid_time, server_time) => {
    const toMilliseconds = (secs) => secs * 1000
    const secondsBeforeRenew = 60 * 5
    const renewSeconds = valid_time - server_time - secondsBeforeRenew

    return toMilliseconds(renewSeconds) + Date.now()
  }

  const handleSkipper = async () => {
    const searchUrl = window.location?.search
    const {
      lead_uuid,
      token: hubToken,
      source,
      utm_source,
      utm_campaign
    } = getQueryParams(searchUrl) || {}

    if (!lead_uuid || !hubToken) {
      navigate("/proposta")
      return null
    }

    try {
      const payload = {
        lead_uuid,
        token: hubToken,
        source,
        utm_source,
        utm_campaign
      }

      const response = await proposalHttpController({
        payload,
        method: "sendSkipper"
        // flow: "default",
        // step: "simulation"
      })

      const { token, valid_time, server_time } = response
      const expires = renewTime(valid_time, server_time)

      setItem("appToken", {
        token,
        expires
      })
    } catch (error) {
      console.log("error:", error)
    } finally {
      navigate("/proposta")
    }
    return null
  }

  useEffect(() => {
    setTimeout(() => {
      handleSkipper()
    }, 2000)
  }, [])

  return {}
}

export default useProcessData
