import { getItem, getUrlParams, renewTime, setItem } from "../utils"
import axios from "axios"

export const authResolve = (options = {}) => {
	return Promise.resolve()
		.then(getAuthData(options))
		.then(checkExpiration(options))
		.then(setHeaders)
		.then(httpInstance(options))
}

const getAuthData = (options) => () => {
	const sessionData = getItem(options.key)
	const urlToken = getUrlParams("token")
	if (sessionData) return { ...JSON.parse(sessionData), urlToken }
	return null
}

const getAuthorization = (url, headers, key) => {
	return axios
		.get(url, { headers })
		.then(({ data }) => data)
		.then(saveAuthData(key))
}

const checkExpiration =
	({ url, key, headers }) =>
	(authData) => {
		if (!authData) return getAuthorization(url, headers, key)
		const now = new Date().getTime()
		const token = { token: `Bearer ${authData.token}` }
		return !!authData.urlToken || authData.expires > now
			? authData
			: getAuthorization(url, { ...headers, ...token }, key)
	}

const setHeaders = (authData) => {
	const headers = { token: `Bearer ${authData.token}` }
	return { authData, headers }
}

const httpInstance =
	(options) =>
	({ authData, headers }) => {
		const http = axios.create({
			headers: { ...headers, ...options.headers },
			baseURL: options.baseURL
		})
		return { authData, headers, http }
	}

export const saveAuthData =
	(key) =>
	({ token, valid_time, server_time }) => {
		const expires = renewTime(valid_time, server_time)
		const authData = { token, expires }
		setItem(key, JSON.stringify(authData))
		return authData
	}
