import { API_ROOT, headers } from "gatsby-env-variables"

import {
  executeRecaptcha,
  removeLastBackslash,
  renewTime,
  setItem,
} from "../utils"
import { authResolve } from "./auth"

const ignoreErrorSteps = ["proposal-summary"]
const ignoreStatusCodes = [500, 503, 504]
const statusToGetErrorMessage = [409]
const nextSteps = {
  "proposal-summary": "proposal-finished",
}

const DEFAULT_ERROR_MESSAGE =
  "Encontramos um erro, por favor tente novamente mais tarde"
const FORM_ERROR_MESSAGE = "Ocorreu um erro, verifique os campos do formulário"

const handleHttpRequest = (config) => {
  const { http } = config

  const onSuccess = (response) => {
    if (response?.data?.step_data?.auth?.token) {
      const { token, valid_time, server_time } = response.data.step_data.auth
      const expires = renewTime(valid_time, server_time)
      const authData = { token, expires }
      setItem("appToken", JSON.stringify(authData))
    }
    return response
  }

  const onFailure = ({ response, config }) => {
    const step = extractStepFromUrl(config)
    if (!response) response = {}
    const status = response.status ?? 500

    if (ignoreErrorSteps.includes(step) && ignoreStatusCodes.includes(status)) {
      const responsePayload = generateResponsePayload(step)
      response.data = responsePayload
      return Promise.resolve(response)
    }

    // TODO: remover essa função quando o back refatorar os erros para CGI e Portablidade
    if (!response?.data?.form_validation_error) {
      const errorFields = generateFormValidationError(response.data)
      if (errorFields) {
        response.data.form_validation_error = errorFields
      }
    }

    const errorMessage = getErrorMessage(response)

    if (!response.data) response.data = {}
    response.data.requestMessage =
      response?.data?.message ?? DEFAULT_ERROR_MESSAGE
    response.data.message = errorMessage
    response.data.statusCode = status

    return Promise.reject(response.data)
  }

  const handleRequest = (config) => {
    const newConfig = { ...config }
    if (newConfig.data) {
      let dataString = JSON.stringify(newConfig.data)

      // Esse replace acontece para evitar problemas de segurança com a AWS enquanto desenvolvemos em localhost
      dataString = dataString.replace("localhost:", "")

      newConfig.data = JSON.parse(dataString)
    }
    return newConfig
  }

  const handleRequestError = (error) => Promise.reject(error)

  http.interceptors.request.use(handleRequest, handleRequestError)
  http.interceptors.response.use(onSuccess, onFailure)
  return http
}

const extractStepFromUrl = (config) => {
  const { url } = config
  const endpoint = removeLastBackslash(url).split("/").pop()
  return endpoint
}

const generateResponsePayload = (step) => {
  const step_navigation = {
    flow: "default",
    current_step: nextSteps[step],
  }
  const step_data = {}
  return { step_navigation, step_data }
}

const getErrorMessage = (response) => {
  const status = response?.status ?? 500
  const step =
    response?.data?.form_validation_error?.step === "softlead-simulation"
  const fields = response?.data?.form_validation_error?.fields
  const errorFields = fields ? Object.values(fields) : {}

  if (step && errorFields.length > 0) {
    const field = errorFields[0].split(" ").shift()
    return `Ocorreu um erro, verifique o campo de ${field.toLocaleLowerCase()}`
  }
  if (response?.data?.form_validation_error) return FORM_ERROR_MESSAGE
  if (statusToGetErrorMessage.includes(status)) {
    const message = response?.data?.message ?? DEFAULT_ERROR_MESSAGE
    return message
  }
  return DEFAULT_ERROR_MESSAGE
}

const getRecaptcha = async (tries = 0) => {
  if (tries > 5) throw new Error(DEFAULT_ERROR_MESSAGE)
  try {
    const tokenRecaptcha = await executeRecaptcha()
    return tokenRecaptcha
  } catch (error) {
    tries++

    return await new Promise((resolve, _) => {
      setTimeout(() => {
        resolve(getRecaptcha(tries))
      }, 400)
    })
  }
}

export const signin = async (hasRecaptcha = true) => {
  const tokenRecaptcha = hasRecaptcha && (await getRecaptcha())

  return authResolve({
    key: "appToken",
    url: `${API_ROOT}/authorization/`,
    baseURL: API_ROOT,
    headers: {
      ...headers,
      ...(tokenRecaptcha && {
        common: { "g-recaptcha-response": tokenRecaptcha },
      }),
    },
  }).then(handleHttpRequest)
}

// TODO: Remover esta função quando for refatorado o objeto de erros pelo backend para CGI e Portabilidade
const generateFormValidationError = (errorData) => {
  if (!errorData?.error || !errorData?.message) return null
  const { message } = errorData
  const messageChunks = message.split(" - ")
  messageChunks.shift()
  const messageEntries = messageChunks.map((message) => {
    const chunks = message.split(":")
    const key = chunks[0].trim().replace(";", "").replace("\n", "")
    const value = chunks[1]
      ? chunks[1].trim().replace(";", "").replace("\n", "")
      : null
    return { key, value }
  })
  const errorFields = {}
  messageEntries.forEach(({ key, value }) => {
    if (!value) return
    if (errorFields[key]) {
      errorFields[key] += `; ${value}`
    } else {
      errorFields[key] = value
    }
  })

  return Object.keys(errorFields).length ? { fields: errorFields } : null
}
