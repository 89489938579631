// TODO: normalizar como consumir os utils. Se de cada pasta individualmente, ou se por um index na pasta de utils
import { analytics } from "../utils/js/analytics"
import { signin } from "./http"
import axios from "axios"

export const get = ({ endpoint, recaptcha = false }) => {
  return signin(recaptcha).then((http) => {
    return http.get(endpoint).then(({ data }) => data)
  })
}

export const put = ({ payload, endpoint }) => {
  return signin().then((http) => {
    return http
      .put(endpoint, { analytics: analytics(), ...payload })
      .then(({ data }) => data)
  })
}

export const post = ({ payload, endpoint }) => {
  return signin().then((http) => {
    return http
      .post(endpoint, { analytics: analytics(), ...payload })
      .then(({ data }) => data)
  })
}

export const hashDocument = (payload) => {
  return signin()
    .then((http) => {
      return http
        .post("/form/hash-document", {
          analytics: analytics(),
          document: payload,
        })
        .then(({ data }) => data)
    })
    .catch((_) => {
      return null
    })
}

export const fetchAddress = (postalCode) => {
  return axios
    .get(`https://viacep.com.br/ws/${postalCode}/json`, {
      timeout: 5000,
    })
    .then(({ data }) => data)
}
