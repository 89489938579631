import { httpRequests } from ".."
import { analytics } from "../../utils/js/analytics"

const proposalServices = {
  post: async ({ payload, flow, step }) =>
    await httpRequests.post({ payload, endpoint: `/form/${flow}/${step}` }),

  put: async ({ payload }) =>
    await httpRequests.put({ payload, endpoint: "/form" }),

  get: async ({ flow, step, recaptcha }) =>
    await httpRequests.get({
      endpoint: `/form/step/${flow}/${step}`,
      recaptcha,
    }),

  financeExpense: async ({ recaptcha }) =>
    await httpRequests.get({
      endpoint: "/form/notice/finance-expenses",
      recaptcha,
    }),

  financeIof: async () =>
    await httpRequests.get({
      endpoint: "/form/notice/finance-iof",
      recaptcha,
    }),

  sendSkipper: async ({ payload }) =>
    await httpRequests.post({
      payload,
      endpoint: "/external/skipper",
      recaptcha,
    }),

  resendToken: async () =>
    await httpRequests.get({
      endpoint: "/form/lead-resend-sms",
      recaptcha,
    }),

  retrieveProposal: async ({ route, recaptcha }) =>
    await httpRequests.get({ endpoint: `/form/${route}`, recaptcha }),

  retrieveProposalRemarketing: async ({
    route,
    recaptcha,
    payload: { remarketingId },
  }) =>
    await httpRequests.get({
      endpoint: `/form/${route}/${remarketingId}`,
      recaptcha,
    }),

  calculateInstallment: async ({ payload, recaptcha }) =>
    await httpRequests.get({
      endpoint: `form/installment-limits?asset_value=${payload.value}`,
      recaptcha,
    }),

  calculateDeadlines: async ({ payload, recaptcha }) =>
    await httpRequests.get({
      endpoint: `form/deadlines?asset_value=${payload}`,
      recaptcha,
    }),

  calculateAssetValue: async ({ payload, recaptcha }) =>
    await httpRequests.get({
      endpoint: `form/dynamic-simulation?asset_value=${payload.asset_input_value}&term=${payload.purchase_time}`,
      recaptcha,
    }),

  editPhoneNumber: async ({ payload, recaptcha }) =>
    await httpRequests.post({
      payload,
      endpoint: "/form/update-phone",
      recaptcha,
    }),

  editEmail: async ({ payload, recaptcha }) =>
    await httpRequests.post({
      payload,
      endpoint: "/form/update-email",
      recaptcha,
    }),

  prospectDocument: async ({ payload }) =>
    await httpRequests.post({ payload, endpoint: `/form/prospect` }),

  accountHolder: async ({ payload, recaptcha }) =>
    await httpRequests.post({
      payload,
      endpoint: "form/account-holder",
      recaptcha,
    }),

  sendLikert: async ({ payload }) =>
    await httpRequests.post({ payload, endpoint: "/form/likert" }),

  sendQualificationAnswers: async ({ payload }) =>
    await httpRequests.post({
      payload,
      endpoint: "/form/lead-qualification-answers",
    }),
}

export default function proposalHttpController({
  payload = {},
  method,
  flow = "",
  step = "",
  route = "",
  recaptcha,
}) {
  const service = proposalServices[method]
  return service({ payload, flow, step, route, recaptcha })
}
