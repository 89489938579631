import Loader from "@mobi/ds/components/Loader"

import useProcessData from "./hooks"

const ProcessData = () => {
  useProcessData()

  return (
    <Loader
      isVisible
      ariaBusy={false}
      loadingText="Carregando página"
      finishedLoadingText="Carregamento finalizado"
    />
  )
}

export default ProcessData
